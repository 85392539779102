import React, { useEffect, useState } from 'react';
import { ArmGlobalNavigation } from '@arm/ce-shared-components';
import config from '../../../temp/config';
import './GlobalNavigation.css';
import { UseGlobalState } from '../../../context';
import { initAzureB2CAction } from '../../../context/action/user';

const GlobalNavigation = ({ fields, rendering }) => {
    const [,dispatch] = UseGlobalState();
    useEffect(() => {
        initAzureB2CAction(dispatch);
    }, []);
    const  user = UseGlobalState();
    const [userData, setUserData] = useState(user[0].user);
    
    useEffect(() => {
        setUserData(user[0].user);
    }, user);
    return (
      <ArmGlobalNavigation
        appName={`developer2.0`} 
        auth={{
            user: userData?.user,
            menuLinks: [
              {
                  label: "Profile",
                  url: config.REACT_APP_SITECORE_PROFILE_LINK
  
              },
              {
                  label: "Settings",
                  url: config.REACT_APP_SITECORE_SETTINGS_LINK
              },
              {
                  label: "Notifications",
                  url: config.REACT_APP_SITECORE_NOTIFICATIONS_LINK
              }
          ],
            loginLink: config.REACT_APP_ORIGIN + config.REACT_APP_PATHNAME + `user-login${typeof window === "undefined"? "": "?returnUrl="+window.location.pathname}`,      
            logoutLink: config.REACT_APP_ORIGIN + config.REACT_APP_PATHNAME + 'user-logout',
            notificationsApi: config.REACT_APP_SITECORE_NOTIFICATIONS_API,
            registerLink: "/register"
          }} 
          searchUrl={`${config.REACT_APP_ORIGIN}/search#numberOfResults=48&q=`}
          isTheme={false} 
          scriptEnvironmentHostUrl={config.REACT_APP_ARM_GLOBALNAVIGATION_HOST}
          />
    );
}; 
const GlobalNavigationClient = () => {
    return (
      <ArmGlobalNavigation 
        appName={`developer2.0`}
        auth={{
          menuLinks: [
            {
                label: "Profile",
                url: config.REACT_APP_SITECORE_PROFILE_LINK

            },
            {
                label: "Settings",
                url: config.REACT_APP_SITECORE_SETTINGS_LINK
            },
            {
                label: "Notifications",
                url: config.REACT_APP_SITECORE_NOTIFICATIONS_LINK
            }
        ],      
          loginLink: `user-login?returnUrl=${window.location.pathname}`,      
          registerLink: "/register",      
          logoutLink: "/user-logout"}} 
          searchUrl={`${config.REACT_APP_ORIGIN}/search#numberOfResults=48&q=`}
          isTheme={false}
          scriptEnvironmentHostUrl={config.REACT_APP_ARM_GLOBALNAVIGATION_HOST}
          />
    );
}; 

export default GlobalNavigation;
export {GlobalNavigationClient};
