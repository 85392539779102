import React from 'react';
import { AdsMasthead } from '@arm/arm-design-system-react';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../../lib/GraphQLData';
import './masthead.css';
import RenderingStyling from '../../../lib/RenderingStyling';


process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
const BreadcrumbQuery = gqlLoader('./query.graphql');

const Masthead = (props) => {
const graphQLResult = props.connectedQuery;
  // Async loading and error handling
  // Remember to never return null from a JSS component when loading,
  // this will break Experience Editor.
  const { error, loading } = graphQLResult;

  // Query results load in using the name of their root field (see query.graphql)
  const { dsItem } = graphQLResult;
  
  let navArray = [];

  if(dsItem !== null && dsItem != undefined)
  {
      let prepareData = (dsItem) => {
        Object.keys(dsItem).map((item, index) => {
            if (item === "parent") {
                if (dsItem["parent"] != null && dsItem["parent"].name != 'sitecore' && dsItem["parent"].name != 'content') {
                    
                    prepareData(dsItem["parent"])
                    
                    navArray.push({
                        name: dsItem["parent"].name,
                        value: dsItem["parent"]
                    })
                }
            }
        })
    }
    prepareData(dsItem)
  }
  
  return (
      <header id="header" className="dev-c-masthead u-layer-4">
        <div data-e2e-id="graphql-connected" className="dev-c-masthead">
          {dsItem !== null && dsItem != undefined &&
            <AdsMasthead {...RenderingStyling(props.rendering.componentName)} >
                {navArray && navArray.length >> 0 &&
                  <ads-breadcrumbs slot="breadcrumbs" sr-label="Breadcrumbs" id="ads-breadcrumbs" class="hydrated">
                    {navArray.map((item,index)=>
                      index != 0 &&
                        <ads-breadcrumb slot="breadcrumb" link={item.value["url"]} class="hydrated">{item.name}</ads-breadcrumb>
                    )
                
                    }
                  </ads-breadcrumbs>
                }
                <h1 slot="heading">{props.sitecoreContext.route.fields.pageTitle.value}</h1>
            </AdsMasthead >
          }
      
        </div>
      </header>
  );
};

export default GraphQLData(BreadcrumbQuery, { name: 'connectedQuery' })(
  Masthead
);
