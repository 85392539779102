import React from 'react';
import {
  Placeholder,
  VisitorIdentification,
} from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
import './assets/app.css';
import loadable from '@loadable/component';
import StarsRatingMobile from './components/SharedComponents/StarRatingMobile';
import JsonLdData from '../src/components/SharedComponents/JsonLd/JsonLdData';
import { getCoveoToken } from '@arm/ce-shared-components';
import config from './temp/config';
import { UseGlobalState } from './context';
import { CredentialAlert } from "@arm/ce-shared-components";
import { getOrganizationEndpoints } from '@coveo/headless';


const Layout = ({ route }) => {
  if(typeof window !=="undefined"){
    const [{ user }] = UseGlobalState();
    if (window.OnetrustActiveGroups && window.OnetrustActiveGroups.includes('C0002')) {
    const script = document.createElement("script");
    script.src = "https://static.cloud.coveo.com/coveo.analytics.js/2/coveoua.js";  
     var currenturl = window.location.href;
    script.onload = async function(){
      const coveoToken = await getCoveoToken(`${config.REACT_APP_COVEOGETSEARCHTOKENURL}`, { token: user.user?.token, isLoggedIn: user.isAuth } );
      const organizationAnalyticsEndpoint =getOrganizationEndpoints(`${config.REACT_APP_COVEOORGANIZATIONID}`).analytics;
      window.coveoua("init", coveoToken,organizationAnalyticsEndpoint);
      window.coveoua("send", "view", {
        contentIdKey: "@clickableuri",
        contentIdValue: currenturl,
        contentType: "DeveloperDocumentation"
      });
    }
     document.head.appendChild(script);
  }
  }
  
  return(<React.Fragment>
    {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
    <Helmet>
      <title>
        {(route.fields &&
          route.fields.pageTitle &&
          route.fields.pageTitle?.value) ||
          'Page'}
      </title>
      <meta name="description" content={route.fields.pageDescription?.value}></meta>
    </Helmet>
    
    <JsonLdData {...route} />

    {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
    <VisitorIdentification />
    <div className="c-app u-flex u-flex-column">
      <div role="navigation" class="dev-c-skip-navigation u-margin-0 u-padding-0 u-position-relative" aria-label="skip navigation">
          <a href="#header" data-track="true" data-track-value="Skip Navigation (Press Enter)">Skip Navigation (Press Enter)</a>
          <a href="#content" data-track="true" data-track-value="Skip to Content (Press Enter)">Skip to Content (Press Enter)</a>
      </div>
      <div role="navigation">
        <Placeholder name="jss-header" rendering={route} />
      </div>
      <Placeholder name="jss-masthead" rendering={route} />
      {route.itemId !== "4afd9f1d-871a-5874-a803-7c8af50a0d03" ? <CredentialAlert  /> : ""}
      <main id="content" className="u-flex-auto u-position-relative">
        <Placeholder name="jss-main" rendering={route} /> 
        {route.itemId !== "4afd9f1d-871a-5874-a803-7c8af50a0d03" ? <StarsRatingMobile uid={route.itemId} /> : ""}
      </main>
      <div className="footer-wrapper">
        <Placeholder name="jss-footer" rendering={route} />
      </div>
    </div>
    <Placeholder name="jss-cookies" rendering={route} />
  </React.Fragment>);
}

export default Layout;
